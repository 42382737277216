<template>
  <div></div>
</template>

<script>
export default {
  name: 'ConfWorkspaces'
}
</script>

<script setup lang="ts">
</script>
