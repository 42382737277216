import type { sessionType } from "@/assets/ts/types/session";

export class Sessions {
  create(item_number: string) {
    return new Promise((resolve, reject) => {
      fetch(process.env.VUE_APP_API_HOST + "/session", {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: [item_number],
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (typeof data.session_id == "string") {
            resolve(data.session_id);
          } else {
            reject(data);
          }
        });
    });
  }

  get(sessionId: string) {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.VUE_APP_API_HOST + "/session/" + sessionId.toLowerCase(),
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (typeof data == "object" && typeof data.error == "undefined") {
            resolve(data as sessionType);
          } else {
            reject(data);
          }
        });
    });
  }

  get_order_data(sessionId: string) {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.VUE_APP_API_HOST +
          "/session/" +
          sessionId.toLowerCase() +
          "/order",
        {
          method: "GET",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (typeof data == "object" && typeof data.error == "undefined") {
            resolve(data as sessionType);
          } else {
            reject(data);
          }
        });
    });
  }

  patch(sessionId: string, item: number, action: string, value: any) {
    return new Promise((resolve, reject) => {
      fetch(
        process.env.VUE_APP_API_HOST + "/session/" + sessionId.toLowerCase(),
        {
          method: "PATCH",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            item: item,
            action: action,
            value: value,
          }),
        },
      )
        .then((response) => response.json())
        .then((data) => {
          if (typeof data == "object" && typeof data.error == "undefined") {
            resolve(data as sessionType);
          } else {
            reject(data);
          }
        });
    });
  }
}
