import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";

import "./index.css";

const numberFormats = {
  "de-DE": {
    currency: {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

const i18n = createI18n({
  locale: "de-DE",
  numberFormats,
});

createApp(App).use(store).use(router).use(i18n).mount("#rubin-items");
