import { createStore } from "vuex";
import loading from "./modules/loading";
import session from "./modules/session"
import workspaces from "./modules/workspaces"

export default createStore({
  modules: {
    loading,
    session,
    workspaces,
  },
});
