type loadingType = {
  runningActions: number;
};

const state = (): loadingType => ({
  runningActions: 0,
});

const getters = {
  isLoading(state: loadingType): boolean {
    return state.runningActions > 0;
  },
};

const actions = {};

const mutations = {
  actionStarted(state: loadingType): void {
    state.runningActions += 1;
  },
  actionFinished(state: loadingType): void {
    state.runningActions -= 1;

    if (state.runningActions < 1) {
      state.runningActions = 0;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
