<template>
  <div className="min-h-full font-light tracking-wider">
    <Loading />

    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Loading from "./components/loading.vue";

export default defineComponent({
  name: "App",
  components: {
    Loading,
  },
  setup() {
    return {};
  },
});
</script>

<style></style>
