<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-50 inset-0 overflow-y-auto" v-if="isLoadingActive">
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <div
        class="fixed inset-0 bg-gray-100 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="
          inline-block
          align-middle
          overflow-hidden
          object-center
          transform
          transition-all
          sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6
        "
      >
        <div class="lds-ripple border-brand-main">
          <div class="border-brand-main"></div>
          <div class="border-brand-main"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "ConfLoading",
  components: {},
  setup() {
    const store = useStore();

    const isLoadingActive = computed(() => {
      return store.getters["loading/isLoading"];
    });

    return {
      isLoadingActive,
    };
  },
});
</script>

<style scoped>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 10em;
  height: 10em;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #a99d8b;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 4em;
    left: 4em;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 8em;
    height: 8em;
    opacity: 0;
  }
}
</style>
