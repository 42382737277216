import type { sessionType } from "@/assets/ts/types/session";

const state = (): sessionType => ({
  session_id: "",
  items: [],
  last_modified: "",
  expires: "",
});

const getters = {
  get(state: sessionType): sessionType {
    return {
      session_id: state.session_id,
      items: state.items,
      last_modified: state.last_modified,
      expires: state.expires,
    };
  },
};

const actions = {};

const mutations = {
  setFull(state: sessionType, session_data: sessionType): void {
    state.session_id = session_data.session_id;
    state.last_modified = session_data.last_modified;
    state.expires = session_data.expires;
    state.items = session_data.items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
