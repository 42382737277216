<template>
  <li v-if="variantValues.length > 1" class="font-bold text-sm block">
    <p>
      <span class="text-black pr-1 text-xs"
        >{{ variant.group }} {{ variant.title }}
        {{ variant.conf_group_idx }}:</span
      >
      <template v-for="(value, valueIdx) in variantValues">
        <span
          v-if="value.match"
          class="font-light text-xs"
          :key="'match_' + valueIdx"
        >
          {{ value.title }}
        </span>
      </template>
    </p>
    <ul class="font-light flex my-2 overflow-x-auto pr-10">
      <li
        v-for="(value, valueIdx) in variantValues"
        :key="valueIdx"
        :data="value.match"
        ref="scrollIntoViewList"
        class="text-center cursor-pointer m-1 pb-2 flex justify-center items-center whitespace-nowrap"
        :class="{
          'border-brand-main border-b-2':
            value.match &&
            !(
              (typeof value.image === 'string' ||
                typeof value.image === 'object') &&
              value.image != ''
            ),
        }"
        @click="
          $emit('patch', {
            action: variantData.idx,
            value: value.idx,
          })
        "
      >
        <div
          v-if="typeof value.image === 'string' && value.image != ''"
          class="relative mx-auto z-20 w-20"
        >
          <img
            :src="value.image"
            class="w-14 rounded-full mx-3"
            :title="value.title"
            :alt="value.title"
            :class="{
              'ring-brand-main ring-offset-2 ring-2': value.match,
            }"
          />
          <p class="text-xxs text-center uppercase w-20 pt-1">
            {{ value.title }}
          </p>
        </div>
        <div
          v-else-if="
            typeof value.image === 'object' && value.image.filename != ''
          "
          class="relative mx-auto z-20 w-20"
          :class="{
            'w-32': variantData.idx.includes('side_stones_id'),
          }"
        >
          <div
            v-if="
              variantData.idx.includes('stone_setting') &&
              value.image.positions[1]
            "
            class="mx-auto w-14 h-14 overflow-hidden rounded-full"
            :class="{
              'ring-brand-main ring-offset-2 ring-2': value.match,
            }"
          >
            <div
              class="w-52 h-52"
              style="transform: translateY(-6%) translateX(-36%)"
            >
              <item-image
                :image-url="value.image.positions[1].filename"
                :image-layer-url="value.image.positions[1].filename_layer"
              />
            </div>
          </div>
          <div
            v-else-if="variantData.idx.includes('side_stones_id')"
            class="mx-auto w-28 h-14 overflow-hidden rounded-full"
            :class="{
              'ring-brand-main ring-offset-2 ring-2': value.match,
            }"
          >
            <div
              class="w-96 h-96"
              style="transform: translateY(-43%) translateX(-10%)"
            >
              <item-image
                :image-url="value.image.filename"
                :image-layer-url="value.image.filename_layer"
              />
            </div>
          </div>
          <template v-else>
            <item-image
              :image-url="value.image.filename"
              :image-layer-url="value.image.filename_layer"
              class="rounded-full"
              :class="{
                'mx-auto ring-brand-main ring-offset-2 ring-2 w-14':
                  value.match,
              }"
            />
          </template>
          <p
            class="text-xxs text-center uppercase w-full pt-1 px-2 overflow-hidden"
          >
            <template v-if="variant.conf_group_idx !== ''">
              {{ value.title }}
            </template>
            <template>
              {{ value.title }}
            </template>
          </p>
        </div>
        <p v-else class="w-full text-xs text-center uppercase px-2">
          {{ value.title }}
        </p>
      </li>
    </ul>
  </li>
</template>

<script setup lang="ts">
import ItemImage from "@/components/itemImage.vue";
import { computed, ref } from "vue";

defineEmits(["patch"]);
const props = defineProps(["variant", "variantData"]);

/**
 * scroll into view
 */

const scrollIntoViewList = ref<HTMLElement[] | null>(null);

/**
 *
 */

const variantValues = computed(() => {
  const finaleValues = [];

  const conf_group_idx = props.variant.conf_group_idx;

  const usedSettings: string[] = [];

  for (let idx = 0; idx < props.variantData.values.length; idx++) {
    const value = props.variantData.values[idx];

    if (conf_group_idx !== "") {
      if (conf_group_idx == "model") {
        if (
          props.variant.conf_groups.setting == value.conf_groups.setting &&
          props.variant.conf_groups.size == value.conf_groups.size
        ) {
          finaleValues.push(value);
        }
      } else if (conf_group_idx == "size") {
        if (
          props.variant.conf_groups.model == value.conf_groups.model &&
          props.variant.conf_groups.setting == value.conf_groups.setting
        ) {
          finaleValues.push(value);
        }
      } else if (conf_group_idx == "setting") {
        if (
          (props.variant.conf_groups.setting == value.conf_groups.setting &&
            value.match == true) ||
          (props.variant.conf_groups.setting !== value.conf_groups.setting &&
            !usedSettings.includes(value.conf_groups.setting))
        ) {
          usedSettings.push(value.conf_groups.setting);
          finaleValues.push(value);
        }
      }
    } else {
      if (
        !(
          (props.variantData.idx.includes("stone_setting") ||
            props.variantData.idx.includes("side_stones_id")) &&
          value.used_dependencies.length > 0
        )
      ) {
        finaleValues.push(value);
      }
    }

    if (scrollIntoViewList.value) {
      for (let selected_idx in scrollIntoViewList.value) {
        if (
          scrollIntoViewList.value[selected_idx].getAttribute("data") === "true"
        ) {
          scrollIntoViewList.value[selected_idx].scrollIntoView({
            block: 'nearest',
            inline: "center",
          });
        }
      }
    }
  }

  return finaleValues;
});
</script>
