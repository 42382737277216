type workspacesType = {
  sessions: string[];
};

const localStorageKey = "workspace";

const state = (): workspacesType => ({
  sessions: [],
});

const getters = {
  getAllSessions(state: workspacesType): string[] {
    return state.sessions;
  },
};

const actions = {
  init(context: any): string {
    const storedSessionsIds: string[] = (
      localStorage.getItem(localStorageKey) || ""
    ).split(";");

    let session_id = "";

    for (const idx in storedSessionsIds) {
      context.commit("addSession", storedSessionsIds[idx]);

      session_id = storedSessionsIds[idx];
    }

    return session_id;
  },
};

const mutations = {
  addSession(state: workspacesType, session_id: string): void {
    if (session_id !== "") {
      state.sessions.push(session_id);
      localStorage.setItem(localStorageKey, state.sessions.join(";"));
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
