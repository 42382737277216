<template>
  <div class="border-brand-main border-b pb-4">
    <ul class="block space-y-4 w-screen px-4 md:w-full">
      <li
        v-for="menuVariant in variantsListPrio"
        :key="menuVariant.idx"
        class="font-bold text-sm block"
      >
        <item-v2-variants-list
          :variant="menuVariant"
          :variant-data="variants[menuVariant.idx]"
          @patch="(event) => $emit('patch', event)"
        />
      </li>
      <li
        class="font-bold text-base flex content-center cursor-pointer"
        @click="addCertificatedDiamondI()"
      >
        <p class="flex-grow leading-5">Diamant aus der Börse auswählen</p>
        <div class="flex-shrink w-6 h-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </li>
      <li
        v-if="variantsList.length > 0"
        class="font-bold text-base flex content-center cursor-pointer"
        @click="showMoreParameter = !showMoreParameter"
      >
        <p class="flex-grow leading-5">Weitere Parameter</p>
        <div class="flex-shrink w-6 h-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            v-if="!showMoreParameter"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            v-else
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
          </svg>
        </div>
      </li>
      <template v-if="showMoreParameter">
        <li
          v-for="menuVariant in variantsList"
          :key="menuVariant.idx"
          class="font-bold text-base block"
        >
          <item-v2-variants-list
            :variant="menuVariant"
            :variant-data="variants[menuVariant.idx]"
            @patch="(event) => $emit('patch', event)"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import ItemImage from "@/components/itemImage.vue";
import ItemV2VariantsList from "@/components/itemV2/itemV2VariantsList.vue";

defineEmits(["patch"]);

const props = defineProps(["item", "sessionId"]);
const showMoreParameter = ref(false);

const variantsList = computed(() => {
  let listOfVariants: {
    idx: string;
    group: string;
    conf_group_idx: string;
    conf_groups: object;
    title: string;
  }[] = [];

  if (props.item) {
    for (let variantIdx in props.item.variants) {
      if (props.item.variants[variantIdx].prio === -1) {
        if (
          Object.keys(props.item.variants[variantIdx].conf_groups).length !== 0
        ) {
          for (let conf_group_idx in props.item.variants[variantIdx]
            .conf_groups) {
            listOfVariants.push({
              idx: props.item.variants[variantIdx].idx,
              group: props.item.variants[variantIdx].group.title,
              conf_group_idx: conf_group_idx,
              conf_groups: props.item.variants[variantIdx].conf_groups,
              title: props.item.variants[variantIdx].title,
            });
          }
        } else {
          listOfVariants.push({
            idx: props.item.variants[variantIdx].idx,
            group: props.item.variants[variantIdx].group.title,
            conf_group_idx: "",
            conf_groups: props.item.variants[variantIdx].conf_groups,
            title: props.item.variants[variantIdx].title,
          });
        }
      }
    }
  }

  listOfVariants.sort((a: any, b: any) => (a.group < b.group ? -1 : 1));

  return listOfVariants;
});

const variantsListPrio = computed(() => {
  let listOfVariants: {
    idx: string;
    prio: number;
    group: string;
    conf_group_idx: string;
    conf_groups: object;
    title: string;
  }[] = [];

  if (props.item) {
    for (let variantIdx in props.item.variants) {
      if (props.item.variants[variantIdx].prio !== -1) {
        listOfVariants.push({
          idx: props.item.variants[variantIdx].idx,
          prio: props.item.variants[variantIdx].prio,
          group: props.item.variants[variantIdx].group.title,
          conf_group_idx: "",
          conf_groups: props.item.variants[variantIdx].conf_groups,
          title: props.item.variants[variantIdx].title,
        });
      }
    }
  }

  listOfVariants.sort((a: any, b: any) => (a.prio < b.prio ? -1 : 1));

  return listOfVariants;
});

const variants = computed(() => {
  let variants: any = {};

  for (let variantIdx in props.item.variants) {
    variants[props.item.variants[variantIdx].idx] =
      props.item.variants[variantIdx];
  }

  return variants;
});

/**
 * Diamantbörse
 */

const addCertificatedDiamondI = () => {
  window.location.href = "/diamantboerse/#/conf/" + props.sessionId;
};
</script>
