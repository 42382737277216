<template>
  <template v-if="retryCounter > maxRetries && !imageUrl.includes('hand1-')">
    Error - unable to load image
  </template>
  <template v-else-if="finaleImageUrl != '' || imageUrl.includes('hand1-')">
    <div class="relative flex items-center justify-center h-full w-full">
      <img
        v-if="imageUrl.includes('hand1-')"
        src="https://images.rubin-trauringe.de/assets/position-hand1-bg.webp"
        class="w-full z-10 absolute inset-0"
      />
      <img
        v-else-if="mainMetal != '' && isOldImage === false"
        :src="
          'https://rubin-website-images.s3.eu-central-1.amazonaws.com/assets/backgrounds/' +
          mainMetal +
          '_' +
          image_pos +
          '.webp'
        "
        class="w-full z-10 absolute inset-0"
      />
      <img
        :src="finaleImageUrl"
        class="w-full z-10 relative md:max-w-xl m-auto md:p-10"
      />
      <img
        v-if="finaleImageLayerUrl != ''"
        :src="finaleImageLayerUrl"
        class="absolute w-full inset-0 z-30 md:max-w-xl m-auto md:p-10"
      />
    </div>
  </template>
  <div class="relative top-0 left-0" v-else>
    <div
      class="flex items-end justify-center h-64 pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="inset-0 bg-gray-100 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span class="hidden sm:inline-block sm:align-middle" aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-middle overflow-hidden object-center transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
      >
        <div class="lds-ripple border-brand-main">
          <div class="border-brand-main"></div>
          <div class="border-brand-main"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch, computed } from "vue";

const props = defineProps(["imageUrl", "imageLayerUrl", "mainMetal"]);

const finaleImageUrl = ref("");
const finaleImageLayerUrl = ref("");

const retryCounter = ref(0);
const retryCounterLayer = ref(0);
const maxRetries = 10;
const isOldImage = ref(false);

/**
 * image position
 */

const image_pos = computed(() => {
  if (props.imageUrl.includes("/pos")) {
    const imageUrlParts = props.imageUrl.split("/");

    return imageUrlParts[imageUrlParts.length - 2];
  }

  return "pos1";
});

/**
 * Das Bild wird so lange neu geladen, bis es vom Server komplett generiert wurde.
 * @param imageUrl
 */
const loadImage = (imageUrl: string) => {
  // console.debug("Try to load", imageUrl);
  retryCounter.value += 1;
  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    finaleImageUrl.value = imageUrl;
    retryCounter.value = 0;
    console.log(img.naturalWidth)
    isOldImage.value = img.naturalWidth == 600;
  };
  img.onerror = () => {
    if (retryCounter.value <= maxRetries) {
      setTimeout(loadImage, 500, imageUrl);
    }
  };
  //
};

loadImage(props.imageUrl);

const loadLayerImage = (imageLayerUrl: string) => {
  //console.log(imageLayerUrl);
  if (imageLayerUrl != "" && typeof imageLayerUrl != "undefined") {
    // console.debug("Try to load", imageLayerUrl);
    retryCounterLayer.value += 1;
    const img = new Image();
    img.src = imageLayerUrl;

    img.onload = () => {
      finaleImageLayerUrl.value = imageLayerUrl;
    };
    img.onerror = () => {
      if (retryCounterLayer.value <= maxRetries) {
        setTimeout(loadImage, 500, imageLayerUrl);
      }
    };
  }
};

loadLayerImage(props.imageLayerUrl);

/**
 * Watcher überwacht, ob sich das Bild verändert hat und startet den Lade-Prozess erneut
 */
watch(
  () => props.imageUrl,
  (imageUrl: string) => {
    loadImage(imageUrl);
  },
  { deep: true },
);

watch(
  () => props.imageLayerUrl,
  (imageUrl: string) => {
    loadLayerImage(imageUrl);
  },
  { deep: true },
);
</script>

<style scoped>
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 10em;
  height: 10em;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #a99d8b;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 4em;
    left: 4em;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 8em;
    height: 8em;
    opacity: 0;
  }
}
</style>
