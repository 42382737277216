import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Init from "../views/init.vue";
import ConfigurationOverview from "../views/configurations/configurationOverview.vue";
import Workspaces from "../views/workspaces.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Init",
    component: Init,
  },
  {
    path: "/workspaces",
    name: "ConfWorkspaces",
    component: Workspaces,
  },
  {
    path: "/conf/:sessionId",
    name: "ConfigurationOverview",
    component: ConfigurationOverview,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
